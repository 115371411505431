.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.vues{
  background: #111111 !important;
  height: 670px;
}
.ties{
  /* background-color: #7ed375;  */
    padding: 25px 35px;
    /* position: relative; */
    /* top: 0; */
    height: 100%;
    /* background: url(../../assets/banner2.png); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    border-radius: 10px;
    
}
.border-webkit{
  /* -webkit-text-stroke: 1px red; */
  text-shadow: 
     1px  1px     #fff, 
    -1px  1px     #fff, 
     1px -1px     #fff, 
    -1px -1px     #fff,
     1px  1px 5px #555;
}

.timers{
  font-size: 65px;
    font-weight: 700;
    color: #7b5c12;

}
.heightkliye{
  width: 100%;
    text-align: center;
}
.maines{
  width: 50%;
  height: 100%;
}
.bgesw{
  background: url(../../assets/banner2.png);
  background-size: cover;
  background-position: center;
}
#demo{
  font-size: 55px;
  font-weight: 600;
}
.popup-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.maines{
  width: 500px;
}
@media (max-width: 768px) {
  .popup-content {
    max-width: 95%;
    width: auto;
  }
.maines{
  width: 300px;
}
  .popup-content img {
    width: 100%;
    height: auto;
  }
}